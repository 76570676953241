// Inspired by https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
// Markdown related samples https://github.com/nemrosim/react-lexical-examples

// Formattings used from old Quill editor: Bold, Italic, Underline, Strikethrough, Heading 1, Heading 2, unordered list, numbered list, Emoji, Clear formatting
// Additional features: Mentions, Links, base64 images (by accident - not yet here)

// Facebook doesn't support a basic component for the toolbar as this is highly customizable. We need to build our own.
// However we can use the existing commands from the Lexical Playground.

import data from "@emoji-mart/data";
import * as i18nde from "@emoji-mart/data/i18n/de.json";
import * as i18nen from "@emoji-mart/data/i18n/en.json";
import Picker from "@emoji-mart/react";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $createHeadingNode, $isHeadingNode, $isQuoteNode, HeadingTagType } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow, $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import EmojiEmotionsRounded from "@mui/icons-material/EmojiEmotionsRounded";
import FormatBoldRounded from "@mui/icons-material/FormatBoldRounded";
import FormatClearRounded from "@mui/icons-material/FormatClearRounded";
import FormatItalicRounded from "@mui/icons-material/FormatItalicRounded";
import FormatListBulletedRounded from "@mui/icons-material/FormatListBulletedRounded";
import FormatListNumberedRounded from "@mui/icons-material/FormatListNumberedRounded";
import FormatStrikethroughRounded from "@mui/icons-material/FormatStrikethroughRounded";
import FormatUnderlinedRounded from "@mui/icons-material/FormatUnderlinedRounded";
import { Button, Paper, alpha, styled } from "@mui/material";
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    CONTROLLED_TEXT_INSERTION_COMMAND,
    FORMAT_TEXT_COMMAND,
    KEY_MODIFIER_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../../hooks/useLanguage";
import { Language } from "../../../translations/main-translations";
import { getSelectedNode } from "../utils/getSelectedNode";
import { sanitizeUrl } from "../utils/url";

const StyledToolbar = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.secondary,
    background: "none",
    border: "none",
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: "50%",
    padding: theme.spacing(0.5, 2),
    ["&:hover"]: {
        border: "none",
        backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
    },
}));

// MUI icons doesn't support h1,h2
const StyledButtonNoIcon = styled(StyledButton)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: "large",
}));

const StyledEmojiMenu = styled(Paper)(({ theme }) => ({
    zIndex: 100000,
    position: "absolute",
}));

const blockTypeToBlockName = {
    bullet: "Bulleted List",
    code: "Code Block",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
    check: "Check List", // Not supported by VD
};

export default function ToolbarPlugin() {
    const language = useLanguage();
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);

    const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>("paragraph");

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const [isLink, setIsLink] = useState(false);
    const [, setIsBold] = useState(false);
    const [, setIsEditable] = useState(() => editor.isEditable());

    // This basically handles the toolbar state.
    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
            }
        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                $updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
        );
    }, [$updateToolbar, activeEditor, editor]);

    useEffect(() => {
        return activeEditor.registerCommand(
            KEY_MODIFIER_COMMAND,
            (payload) => {
                const event: KeyboardEvent = payload;
                const { code, ctrlKey, metaKey } = event;

                if (code === "KeyK" && (ctrlKey || metaKey)) {
                    event.preventDefault();
                    return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
                }
                return false;
            },
            COMMAND_PRIORITY_NORMAL,
        );
    }, [activeEditor, isLink]);

    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            $setBlocksType(selection, () => $createParagraphNode());
        });
    };

    const formatHeading = (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createHeadingNode(headingSize));
            });
        } else {
            // Lexical playground used "normal" to remove headings. We toogle this with the headline buttons.
            formatParagraph();
        }
    };

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();

                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        let updatedNode = node;
                        if (idx === 0 && anchor.offset !== 0) {
                            updatedNode = updatedNode.splitText(anchor.offset)[1] || updatedNode;
                        }
                        if (idx === nodes.length - 1) {
                            updatedNode = updatedNode.splitText(focus.offset)[0] || updatedNode;
                        }

                        if (updatedNode.__style !== "") {
                            updatedNode.setStyle("");
                        }
                        if (updatedNode.__format !== 0) {
                            updatedNode.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(updatedNode).setFormat("");
                        }
                    } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    } else if ($isDecoratorBlockNode(node)) {
                        node.setFormat("");
                    }
                });
            }
        });
    }, [activeEditor]);

    return (
        <StyledToolbar>
            <StyledButton onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}>
                <FormatBoldRounded />
            </StyledButton>
            <StyledButton onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}>
                <FormatItalicRounded />
            </StyledButton>
            <StyledButton onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}>
                <FormatUnderlinedRounded />
            </StyledButton>
            <StyledButton onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}>
                <FormatStrikethroughRounded />
            </StyledButton>

            <StyledButtonNoIcon onClick={() => formatHeading("h1")}>H1</StyledButtonNoIcon>
            <StyledButtonNoIcon onClick={() => formatHeading("h2")}>H2</StyledButtonNoIcon>

            <StyledButton onClick={() => formatBulletList()}>
                <FormatListBulletedRounded />
            </StyledButton>
            <StyledButton onClick={() => formatNumberedList()}>
                <FormatListNumberedRounded />
            </StyledButton>

            <StyledButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                <EmojiEmotionsRounded />
            </StyledButton>
            {showEmojiPicker && (
                <StyledEmojiMenu>
                    <Picker
                        data={data}
                        previewPosition="none"
                        theme="light"
                        onEmojiSelect={(x: any) => {
                            activeEditor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, x.native);
                            setShowEmojiPicker(false);
                        }}
                        i18n={language === Language.DE ? i18nde : i18nen}
                        locale={language === Language.DE ? "de" : "en"}
                    />
                </StyledEmojiMenu>
            )}
            <StyledButton onClick={() => clearFormatting()}>
                <FormatClearRounded />
            </StyledButton>
        </StyledToolbar>
    );
}
